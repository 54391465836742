// ** Reducers Imports
import navbar from "./navbar"
import layout from "./layout"
import todo from "@src/views/apps/todo/store"
import chat from "@src/views/apps/chat/store"
import users from "@src/views/apps/user/store"
import email from "@src/views/apps/email/store"
import invoice from "@src/views/apps/invoice/store"
import calendar from "@src/views/apps/calendar/store"
import ecommerce from "@src/views/apps/ecommerce/store"
import dataTables from "@src/views/tables/data-tables/store"
import permissions from "@src/views/apps/roles-permissions/store"
import prefrences from "../views/frontend/Preferences/store"
import auth from "./authentication"
import common from "./common"
// ** Frontend Reducer
import tests from "../views/frontend/tests/store"
import tasks from "../views/frontend/tasks/store"
import favorites from "../views/frontend/favorites/store"
import commonFavorite from "./favorites"
import commonChats from "./chats"
import commonUser from "./user"
import commonAssignments from "./assignments"
import frontAssignments from "../views/frontend/assignments/store"
import individualFeatures from "./individual_features"
// Admin Reducers
import adminChallenges from "../views/admin/challenges/store"
import adminGroups from "../views/admin/groups/store"
import adminTasks from "../views/admin/tasks/store"
import adminTests from "../views/admin/tests/store"
import adminContents from "../views/admin/contents/store"
import adminUsers from "../views/admin/users/store"
import adminEvents from "../views/admin/events/store"
import adminPackages from "../views/admin/packages/store"
import adminReviews from "../views/admin/reviews/store"
import adminFeatures from "../views/admin/features/store"
import adminSections from "../views/admin/sections/store"
import adminEmailConfigs from "../views/admin/email-configuration/store"
import adminSMSConfigs from "../views/admin/sms-configuration/store"
import adminMeetings from "../views/admin/meeting/store"
import adminNotifications from "../views/admin/notification-template/store"
import adminInquiries from "../views/admin/inquiries/store"
import adminDashboard from "../views/admin/dashboard/store"
import adminStatistics from "../views/admin/statistics/store"


// Clinic Reducers
import clinicChallenges from "../views/clinic/challenges/store"
import clinicGroups from "../views/clinic/groups/store"
import clinicTasks from "../views/clinic/tasks/store"
import clinicAssignments from "../views/clinic/assignments/store"
import clinicFeatures from "../views/clinic/features/store"
import clinicTrainings from "../views/clinic/trainings/store"
import clinicMettings from "../views/clinic/meeting/store"
import clinicPatients from "../views/clinic/patients/store"
import adminPayment from "../views/admin/payments/store"
import clinicEmployee from "../views/clinic/employees/store"
import clinicDashboard from '../views/clinic/dashboard/store'
import clinicSections from '../views/clinic/sections/store'
import clinicNewSections from '../views/clinic/newsections/store'
import clinicStatistics from '../views/clinic/statistics/store'

// Front Reducers
import frontChallenges from "../views/frontend/challenges/store"
import frontSections from "../views/frontend/sections/store"
import ratings from "./ratings"
import frontGroups from "../views/frontend/groups/store"
import frontMeeting from "../views/frontend/meeting/store"
import termAndConditions from "../views/frontend/term-and-conditions/store"
import privacy_policy from "../views/frontend/privacy_policy/store"
import frontAccounts from '../views/frontend/account/store'
import frontDashboard from '../views/frontend/dashboard/store'
import frontNotifications from '../views/frontend/notifications/store'
import recommendation from '../views/frontend/recommendation/store'
import homePageUrgentAlerts from '../views/frontend/home/store'
import newDiscovery from '../views/frontend/newdiscovery/store'
// zoom credential
import zoomCredential from "../views/zoom/store"

const rootReducer = {
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  permissions,
  tests,
  tasks,
  commonChats,
  commonUser,
  favorites,
  commonAssignments,
  // common reducers
  ratings,
  commonFavorite,
  common,
  // admin reducers
  adminChallenges,
  adminGroups,
  adminTasks,
  adminTests,
  adminContents,
  adminUsers,
  adminEvents,
  adminPackages,
  adminReviews,
  adminFeatures,
  adminSections,
  adminEmailConfigs,
  adminSMSConfigs,
  adminMeetings,
  adminNotifications,
  adminInquiries,
  adminDashboard,
  adminStatistics,
  // clinic reducers
  clinicChallenges,
  clinicGroups,
  clinicTasks,
  clinicAssignments,
  clinicFeatures,
  clinicTrainings,
  clinicMettings,
  clinicPatients,
  adminPayment,
  prefrences,
  clinicEmployee,
  clinicDashboard,
  clinicSections,
  clinicNewSections,
  clinicStatistics,
  //frontEnd Reducers
  frontChallenges,
  frontAssignments,
  individualFeatures,
  frontSections,
  frontGroups,
  frontMeeting,
  termAndConditions,
  privacy_policy,
  frontAccounts,
  frontDashboard,
  frontNotifications,
  recommendation,
  homePageUrgentAlerts,
  newDiscovery,
  //zoom Credential
  zoomCredential
}

export default rootReducer
