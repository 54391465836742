import { API_HOST } from "./config"
export default {
  // ADMIN ROUTES
  ADMIN_SECTIONS: `${API_HOST}admin/sections/all_sections`,
  ADMIN_ADD_SECTION: `${API_HOST}admin/sections/create_section`,
  ADMIN_EDIT_SECTION: `${API_HOST}admin/sections/edit_section`,
  ADMIN_DELETE_SECTION: `${API_HOST}admin/sections/delete_section`,
  // ADMIN SECTION CONTENT
  ADMIN_SECTION_CONTENTS: `${API_HOST}admin/sections/get_section_content`,
  ADMIN_SECTION_ADD_CONTENT: `${API_HOST}admin/sections/add_section_content`,
  ADMIN_SECTION_EDIT_CONTENT: `${API_HOST}admin/sections/update_section_content`,
  ADMIN_SECTION_DELETE_CONTENT: `${API_HOST}admin/sections/delete_content`,

  // ADMIN SECTION CATEGORIES
  ADMIN_SECTION_CATEGORIES: `${API_HOST}admin/sections/get_section_categories`,
  ADMIN_SECTION_ADD_CATEGORY: `${API_HOST}admin/sections/add_section_category`,
  ADMIN_SECTION_EDIT_CATEGORY: `${API_HOST}admin/sections/edit_section_category`,
  ADMIN_SECTION_DELETE_CATEGORY: `${API_HOST}admin/sections/delete_section_category`,
  // ADMIN SECTION CATEGORY CONTENT
  ADMIN_SECTION_CATEGORY_CONTENTS: `${API_HOST}admin/sections/get_category_content`,
  ADMIN_SECTION_ADD_CATEGORY_CONTENT: `${API_HOST}admin/sections/add_category_content`,
  ADMIN_SECTION_EDIT_CATEGORY_CONTENT: `${API_HOST}admin/sections/update_category_content`,

  // ADMIN SECTION CLASSES
  ADMIN_SECTION_CLASSES: `${API_HOST}admin/sections/get_section_classes`,
  ADMIN_SECTION_ADD_CLASS: `${API_HOST}admin/sections/add_section_class`,
  ADMIN_SECTION_EDIT_CLASS: `${API_HOST}admin/sections/edit_section_class`,
  ADMIN_SECTION_DELETE_CLASS: `${API_HOST}admin/sections/delete_section_class`,
  // ADMIN SECTION CLASS CONTENT
  ADMIN_SECTION_CLASS_CONTENTS: `${API_HOST}admin/sections/get_class_content`,
  ADMIN_SECTION_ADD_CLASS_CONTENT: `${API_HOST}admin/sections/add_class_content`,
  ADMIN_SECTION_EDIT_CLASS_CONTENT: `${API_HOST}admin/sections/update_class_content`,

  // ADMIN CLASS LESSONS
  ADMIN_SECTION_CLASS_LESSONS: `${API_HOST}admin/sections/get_class_lessons`,
  ADMIN_SECTION_ADD_CLASS_LESSON: `${API_HOST}admin/sections/add_class_lesson`,
  ADMIN_SECTION_EDIT_CLASS_LESSON: `${API_HOST}admin/sections/edit_class_lesson`,
  ADMIN_SECTION_DELETE_CLASS_LESSON: `${API_HOST}admin/sections/delete_class_lesson`,
  // ADMIN CLASS LESSON CONTENT
  ADMIN_SECTION_CLASS_LESSON_CONTENTS: `${API_HOST}admin/sections/get_lesson_content`,
  ADMIN_SECTION_ADD_CLASS_LESSON_CONTENT: `${API_HOST}admin/sections/add_lesson_content`,
  ADMIN_SECTION_EDIT_CLASS_LESSON_CONTENT: `${API_HOST}admin/sections/update_lesson_content`,
  ADMIN_SECTION_CONTENT_REORDER: `${API_HOST}admin/sections/update_content_orders`,

  // FRONT SECTIONS
  FRONT_SECTIONS: `${API_HOST}front/discovery/get`,
  FRONT_SECTION_CONTENT_AND_CLASSES: `${API_HOST}front/discovery/get_section_content_and_classes`,
  SIGNATURE: `${API_HOST}front/dashboard/save_signature`,
  BUSINESS_QUESTIONS: `${API_HOST}front/dashboard/update_business_questionnaire`,
  NOTIFY: `${API_HOST}front/new_discovery/notify_me`,
  COMPLETE_CLASS: `${API_HOST}front/new_discovery/complete_class`,
  CHATGPT_TEXT: `${API_HOST}clinics/AI_Meditations/promptToText`,
  CHATGPT_HISTORY: `${API_HOST}clinics/AI_Meditations/meditationHistory`,
  CHATGPT_VOICE: `${API_HOST}clinics/AI_Meditations/textToVoice`,
  ADD_TO_WATCH: `${API_HOST}front/new_discovery/add_to_watchlist`,
  FEED_BACK_VALUE: `${API_HOST}front/new_discovery/meditation_feedback`,
  GET_WATCH_LIST_DATA: `${API_HOST}front/new_discovery/get_watchlist`,
  CHECK_WATCHLIST_COMPLETE: `${API_HOST}front/new_discovery/mark_watchlist`,
  REMOVE_WATCH_LIST: `${API_HOST}front/new_discovery/remove_from_watchlist`,
  FRONT_SECTION_CATEGORIES: `${API_HOST}front/discovery/get_section_categories`,
  FRONT_SECTION_CONTENT_OVERVIEW: `${API_HOST}front/discovery/get_content_overview`,
  FRONT_SECTION_CATEGORY_CONTENT: `${API_HOST}front/discovery/get_category_content`,
  FRONT_SECTION_CLASS_CONTENT_AND_LESSONS: `${API_HOST}front/discovery/get_class_content_lessons`,
  FRONT_SECTION_LESSON_CONTENT: `${API_HOST}front/discovery/get_lesson_content`,
  FRONT_SECTION_CONTENT_DETAIL: `${API_HOST}front/discovery/get_content_overview`,
  GET_LESSONS_COMPLETE_CONTENTS: `${API_HOST}front/new_discovery/get_lesson_content_detail`,
  GET_LESSONS_TASKS: `${API_HOST}front/new_discovery/get_lesson_tasks`,
  CREATE_TASKS: `${API_HOST}front/new_discovery/get_lesson_tasks`,
  JOIN_A_CLASS: `${API_HOST}front/new_discovery/join_class`,

  // CLINIC SECTIONS
  CLINIC_SECTIONS: `${API_HOST}clinics/sections/all_sections`,
  CLINIC_ADD_SECTION: `${API_HOST}clinics/sections/create_section`,
  CLINIC_EDIT_SECTION: `${API_HOST}clinics/sections/edit_section`,
  CLINIC_DELETE_SECTION: `${API_HOST}clinics/sections/delete_section`,

  // CLINIC SECTION CONTENT
  CLINIC_SECTION_CONTENTS: `${API_HOST}clinics/sections/get_section_content`,
  CLINIC_SECTION_ADD_CONTENT: `${API_HOST}clinics/sections/add_section_content`,
  CLINIC_SECTION_EDIT_CONTENT: `${API_HOST}clinics/sections/update_section_content`,
  CLINIC_SECTION_DELETE_CONTENT: `${API_HOST}clinics/sections/delete_content`,

  // CLINIC SECTION CATEGORIES
  CLINIC_SECTION_CATEGORIES: `${API_HOST}clinics/sections/get_section_categories`,
  CLINIC_SECTION_ADD_CATEGORY: `${API_HOST}clinics/sections/add_section_category`,
  CLINIC_SECTION_EDIT_CATEGORY: `${API_HOST}clinics/sections/edit_section_category`,
  CLINIC_SECTION_DELETE_CATEGORY: `${API_HOST}clinics/sections/delete_section_category`,

  // CLINIC SECTION CATEGORY CONTENT
  CLINIC_SECTION_CATEGORY_CONTENTS: `${API_HOST}clinics/sections/get_category_content`,
  CLINIC_SECTION_ADD_CATEGORY_CONTENT: `${API_HOST}clinics/sections/add_category_content`,
  CLINIC_SECTION_EDIT_CATEGORY_CONTENT: `${API_HOST}clinics/sections/update_category_content`,

  // CLINIC SECTION CLASSES
  CLINIC_SECTION_CLASSES: `${API_HOST}clinics/sections/get_section_classes`,
  CLINIC_SECTION_ADD_CLASS: `${API_HOST}clinics/sections/add_section_class`,
  CLINIC_SECTION_EDIT_CLASS: `${API_HOST}clinics/sections/edit_section_class`,
  CLINIC_SECTION_DELETE_CLASS: `${API_HOST}clinics/sections/delete_section_class`,
  // CLINIC SECTION CLASS CONTENT
  CLINIC_SECTION_CLASS_CONTENTS: `${API_HOST}clinics/sections/get_class_content`,
  CLINIC_SECTION_ADD_CLASS_CONTENT: `${API_HOST}clinics/sections/add_class_content`,
  CLINIC_SECTION_EDIT_CLASS_CONTENT: `${API_HOST}clinics/sections/update_class_content`,

  // CLINIC CLASS LESSONS
  CLINIC_SECTION_CLASS_LESSONS: `${API_HOST}clinics/sections/get_class_lessons`,
  CLINIC_SECTION_ADD_CLASS_LESSON: `${API_HOST}clinics/sections/add_class_lesson`,
  CLINIC_SECTION_EDIT_CLASS_LESSON: `${API_HOST}clinics/sections/edit_class_lesson`,
  CLINIC_SECTION_DELETE_CLASS_LESSON: `${API_HOST}clinics/sections/delete_class_lesson`,
  // CLINIC CLASS LESSON CONTENT
  CLINIC_SECTION_CLASS_LESSON_CONTENTS: `${API_HOST}clinics/sections/get_lesson_content`,
  CLINIC_SECTION_ADD_CLASS_LESSON_CONTENT: `${API_HOST}clinics/sections/add_lesson_content`,
  CLINIC_SECTION_EDIT_CLASS_LESSON_CONTENT: `${API_HOST}clinics/sections/update_lesson_content`,
  CLINIC_DEFAULT_STATUS: `${API_HOST}clinics/sections/get_default_status`,
  CLINIC_EDIT_DEFAULT_STATUS: `${API_HOST}clinics/sections/update_default_status`,
  GET_ALL_SHARED_CLASSES: `${API_HOST}clinics/discovery/get_shared_classes`,

  //NEW DISCOVERY
  LEAVE_CLASS_RESPONSE:`${API_HOST}front/new_discovery/get_class_leaving_reasons`,
  RECENT_CLASSESS: `${API_HOST}front/new_discovery/get_recent_classes`,
  GET_LESSON_MEDITATION: `${API_HOST}front/new_discovery/get_lesson_meditations`,

  ACTIVE_CLASSESS: `${API_HOST}front/new_discovery/get_active_classes`,
  POPULAR_CLASSESS: `${API_HOST}front/new_discovery/get_popular_classes`,
  RECOMMENDED_MEDITATION: `${API_HOST}front/new_discovery/get_recommended_meditations`,
  RECOMMENDED_CLASSESS: `${API_HOST}front/new_discovery/get_recommended_classes`,
  COMPLETE_MEDITATION: `${API_HOST}front/new_discovery/get_complete_meditation`,
  CLASS_DETAIL: `${API_HOST}front/new_discovery/get_class_detail`,
  RESOURSE_DETAIL: `${API_HOST}front/new_discovery/get_resources`,
  LESSON_CONTENT_DETAIL: `${API_HOST}front/new_discovery/get_lesson_content_detail`,
  MEDITATION_TASKS: `${API_HOST}front/new_discovery/get_meditation_tasks`,
  MEDITATION_MORE_TASKS: `${API_HOST}front/new_discovery/get_meditation_audios`,
  MARK_TASK: `${API_HOST}/front/new_discovery/task_done`,
  GET_CATEGORIES: `${API_HOST}/front/new_discovery/get_categories`,
  GET_CATEGORIES_BY_CLASS: `${API_HOST}front/new_discovery/get_classes_by_category`,
  LEAVE_CLASS: `${API_HOST}front/new_discovery/leave_class`,
  GET_CATEGORIES_BY_MEDITATIONS: `${API_HOST}front/new_discovery/get_meditations_by_category`,
  GET_LESSON_CONTENT: `${API_HOST}front/new_discovery/get_class_lessons_and_lesson_contents`,
  GET_STORIES: `${API_HOST}front/new_discovery/get_stories`,
  DEL_STORIES: `${API_HOST}front/new_discovery/delete_story`,
  ADD_STORIES: `${API_HOST}front/new_discovery/add_story`,
  UPDATE_STORIES: `${API_HOST}front/new_discovery/update_story`,
  GET_ALL_RECENT_CLASSES: `${API_HOST}front/new_discovery/get_recent_classes_all`,
  UPDATE_WATCH_TIME: `${API_HOST}front/new_discovery/update_video_watchtime`,
  GET_WATCHED_TIME: `${API_HOST}front/new_discovery/get_video_watchtime`,
  FAVOURITE_CLASSES: `${API_HOST}front/new_discovery/favorite_unfavorite_content`,
  GET_MEDITATIONS: `${API_HOST}front/new_discovery/get_all_meditations`,
  GET_POPULAR_MEDITATIONS: `${API_HOST}front/new_discovery/get_popular_meditations`,
  GET_RECOMMENDED_MEDITATIONS: `${API_HOST}front/new_discovery/get_recommended_meditations`,
  UPDATE_LOG: `${API_HOST}front/dashboard/update_logs`,
  LISTEN_TIME: `${API_HOST}front/new_discovery/update_audio_listentime`,
  GET_LISTEN_TIME: `${API_HOST}front/new_discovery/get_audio_listentime`,
  COUNT_AUDIO: `${API_HOST}front/new_discovery/add_audio_listen_count`,
  RECENT_MEDITAIONS: `${API_HOST}front/new_discovery/recently_visited_meditations`,

  //welcome Screens

  GET_INTRO: `${API_HOST}front/dashboard/get_intro`,
  UPDATE_INTRO: `${API_HOST}front/dashboard/update_intro`,
  UPDATE_QUESTIONIER_INTRO: `${API_HOST}front/dashboard/update_personal_questionnaire`,
  LOGIN_INTRO:`${API_HOST}front/authentication/login`
}
