//** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { meeting } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'

export const getPrefrences = createAsyncThunk('prefrences/getPrefrences', async () => {
  const response = await axiosRequest.get(meeting.GET_QUESTIONIER_DATA)
  return { data: response?.data?.data }
})

export const prefrencesSlice = createSlice({
  name: 'prefrences',
  initialState: {
    prefrencesLoading: false,
    getPrefrencesData: [],
    value: [],
    showQuestionierData: false
  },
  reducers: {
    setQuestionierData: (state, action) => {
      state.value = action.payload
      console.log("====state.setQuestionierData=====343434=======", state.value)
    },
    setQuestionierDisplay: (state, action) => {
      state.showQuestionierData = action.payload
      console.log("====state.setQuestionierData=====343434=======", state.showQuestionierData)
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getPrefrences.pending, (state) => {
        state.prefrencesLoading = true
      })
      .addCase(getPrefrences.rejected, (state) => {
        state.prefrencesLoading = false
      })
      .addCase(getPrefrences.fulfilled, (state, { payload }) => {
        state.getPrefrencesData = payload?.data
        console.log("=========6868========", state.getPrefrencesData)
        state.prefrencesLoading = false
      }) // Removed trailing comma here
  }
})

export const { setQuestionierData } = prefrencesSlice.actions
export const { setQuestionierDisplay } = prefrencesSlice.actions
export default prefrencesSlice.reducer
